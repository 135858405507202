<template>
   <div class="transits-info">
      <div class="page-title">         
      </div>
      <div class="loading-spinner-area" v-show="pageLoading">
         <img class="screen-centered-img" src="../assets/spinner.gif"/>
      </div>
      <div class="transit-input" align="center">
         <table width="50%" border="0" class="preferences-input-table">
            <tr>
                  <td width="20%" align="left">&nbsp;<b>Planet</b></td>
                  <td width="80%" align="left">
                     <select class="month" name="cs" v-model="criteria.planetNo" style="float:left;margin:5px" @change="getTransits">
                        <option value="0">SUN</option>
                        <option value="1">MOON</option>
                        <option value="4">MARS</option>
                        <option value="2">MERCURY</option>
                        <option value="5">JUPITER</option>
                        <option value="3">VENUS</option>
                        <option value="6">SATURN</option>
                        <option value="7">RAHU</option>
                        <option value="8">KETU</option>
                     </select>
                  </td>
            </tr>
            <tr>
                  <td width="20%" align="left">&nbsp;<b>Type</b></td>
                  <td width="80%" align="left">
                     <select class="month" name="aynamsa" v-model="criteria.transitType" style="float:left;margin:5px" @change="getTransits">
                        <option value="rasi">Sign / Rasi</option>
                        <option value="nakshatra">Star / Nakshatra</option>
                        <option value="nakshatrapada">Star Division / Nakshatra Pada</option>
                        <option value="speed">Direct / Retrograde Periods</option>
                        <option value="degree100">360 Degrees - 00:00:00</option>
                        <option value="degree25">360 Degrees - 00:15:00</option>
                        <option value="degree50">360 Degrees - 00:30:00</option>
                        <option value="degree75">360 Degrees - 00:45:00</option>
                     </select>
                  </td>
            </tr>
         </table>
      </div>

      <div class="summary-table">
         <table class="transits-table" width="100%" border="0" v-for="(days, name) in transits" :key="name">
            <thead>
               <tr>
                  <th>{{name}}</th>
               </tr>
            </thead>
            <tbody>
               <tr v-for="event in days" :key="event">
                  <td align="left" v-if="event.current"  style="background:#FFFF66">
                     <b>{{event.typeString}}</b><template v-if="event.currentPercentage >= 0">&nbsp;&nbsp;(<b>{{event.currentPercentage}}%</b>)</template>
                     <br/>
                     <b>{{event.number}} - {{event.name}}</b>
                     <i v-if="event.alternateName"> &nbsp;/&nbsp; {{event.alternateName}}</i>
                     <template v-if="event.lord">,&nbsp;&nbsp; {{event.lord}}</template><br/>
                     <b>{{event.startDay}}</b> {{event.startTime}}, {{event.startWeekDay}}<br/>
                     <b>{{event.endDay}}</b> {{event.endTime}}, {{event.endWeekDay}}<br/>
                     {{event.duration}}
                  </td>
                  <td align="left" v-else>
                     <b>{{event.typeString}}</b><template v-if="event.currentPercentage >= 0">&nbsp;&nbsp;(<b>{{event.currentPercentage}}%</b>)</template>
                     <br/>
                     <b>{{event.number}} - {{event.name}}</b>
                     <i v-if="event.alternateName"> &nbsp;/&nbsp; {{event.alternateName}}</i>
                     <template v-if="event.lord">,&nbsp;&nbsp; {{event.lord}}</template><br/>
                     <b>{{event.startDay}}</b> {{event.startTime}}, {{event.startWeekDay}}<br/>
                     <b>{{event.endDay}}</b> {{event.endTime}}, {{event.endWeekDay}}<br/>
                     {{event.duration}}
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</template>
<script>

import HTTP from '../http-axios';
import { queryString } from '../util.js'


export default {
	data() {
		return {
			pageLoading: false,
			transits: {}, 
			criteria: {
				client: '',
				username: '',
				email: '',
				ipAddress: '',
				deviceType: 'SS',

				year: new Date().getFullYear(),
				month: new Date().getMonth() + 1,
				day: new Date().getDate(),

				hour: new Date().getHours(),
				minute: new Date().getMinutes(),
				second: new Date().getSeconds(),

				timeOffsetMinutes: (-1 * new Date().getTimezoneOffset()),
				timeOffset: ((-1 * new Date().getTimezoneOffset()) / 60.0),

				location: 'geocentric',
				latitude: 0,
				longitude: 0,

				ayanamsa: 1,
				rahuNode: 10,
				topocentricPlanets: false,

				dasaBuktiDaysInYear: 365.256,

				conjunctionDegreeLimit: 20,
				aspectDegreeLimit: 15,

				chartStyle: 1,
				chartTheme: 0,
				chartSize: 0,

            nutationRequired: false,
            hinduSunrise: true,

            planetNo:  (localStorage.getItem("tsplanet") ? Number.parseInt(localStorage.getItem("tsplanet")) : 0 ),
            transitType: ( localStorage.getItem("tstype") ? localStorage.getItem("tstype") : 'rasi')
			},
		}
	},
	methods: {
		queryString: function( obj ) {
			return queryString(obj);
		},
		getTransits: function() {
			this.pageLoading = true;
         this.transits = {};
			HTTP().post(`/api/data/transit/` + this.criteria.transitType + `?` + this.queryString(this.criteria))
			.then(response => {
				this.transits = response.data
				this.pageLoading = false
			})
			.catch(e => {
				console.log(e)
			})
		},
  	},
	created() {
		this.getTransits();
	},
   beforeUnmount() {
      // Capture chart settings before leaving the component
      localStorage.setItem("tsplanet", this.criteria.planetNo)
      localStorage.setItem("tstype", this.criteria.transitType)
   }
}
</script>

<style scoped>

.page-title {
	border: 0px solid;
	width: 100%;
   margin-bottom: 10px;
}

.loading-spinner-area {
   align-content: center;
}
.screen-centered-img {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
}

.transit-input {
   padding-bottom: 20px;
    margin-top:10px;
    width: 100%;
    align-content: center;   
}

.transits-info {
	border: 0px solid;
	display:flex;
	flex-direction: column;
	align-items: center;
   align-content: center;
   justify-content: center;
	width:100%;
}

.summary-table {
	width: 95%;
	padding: 10px auto;
}

.transits-table {
    border-collapse: collapse;
    margin: 0px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    border-radius: 5px;
 }

.transits-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    text-align: left;
    font-weight: bold;
}

.transits-table th {
    padding: 12px 15px;
	 font-size: 20px;
}

.transits-table td {
    padding: 10px 10px;
    color: #344E41;
}

.transits-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

select {
  margin: 1px;
  padding: 5px;
  height: 30px;
  cursor:pointer;
  display:inline-block;
  position:relative;
  font:normal 14px;
  color:black;
  border:2px solid  #087F8C;
  border-color: 1px solid #087F8C;
  outline: none;
  border-radius: 10px;  
}

select:focus {
  border: 2px solid #ACD8AA;
}

</style>
