<template>
	<div class="screen-wrapper" v-if="criteria">
		<div class="content-area">

			<div class="loading-spinner-area" v-show="pageLoading">
				<img class="screen-centered-img" src="../../assets/spinner.gif"/>
			</div>

			<div class="header-area" v-if="menuItem !== 'home' && menuItem !== 'view-pdf' && menuItem !== 'view-summary-pdf'">
				<ShowHeader :horoscope="horoscope" @activeMenuItem="activateMenuItem"/>
			</div>
			<ShowPlanetaryStrength v-if="menuItem == 'planetary-strength'" :horoscope="horoscope" />
			<ShowNatalPlanets v-if="menuItem == 'natal-planets'" :id="id" :horoscope="horoscope" />
			<ShowAspects v-if="menuItem == 'aspects'" :horoscope="horoscope" />
			<ShowConjunction v-if="menuItem == 'conjunction'" :horoscope="horoscope" />
			<ShowDivisionalChart v-if="menuItem == 'varga'" :horoscope="horoscope" />
			<ShowBhavaChart v-if="menuItem == 'bhava'" :criteria="criteria" :horoscope="horoscope" />
			<ShowPanchanga v-if="menuItem == 'panchanga'" :horoscope="horoscope" />
			<ShowVimsottariDasas v-if="menuItem == 'vimsottri-dasa'" :horoscope="horoscope" />
			<ShowFilteredVimsottariDasas v-if="menuItem == 'filtered-vimsottri-dasa'" :horoscope="horoscope" />
			<ShowPDFSummary v-if="menuItem == 'view-summary-pdf'" :criteria="criteria" />
			<ShowPDF v-if="menuItem == 'view-pdf'" :criteria="criteria" />
			<PurchaseCompleteNatalChart v-if="menuItem == 'buy-complete-pdf'" :criteria="criteria" @activeMenuItem="activateMenuItem" />
			<ViewInterpretationReports v-if="menuItem == 'chart-interpretations'" :criteria="criteria" @activeMenuItem="activateMenuItem" />
			<PurchaseFoundationalReport v-if="menuItem == 'purchase-foundational-report'" :criteria="criteria" @activeMenuItem="activateMenuItem" />
			<PurchasePlanetaryPositionsReport v-if="menuItem == 'purchase-planetary-positions-report'" :criteria="criteria" @activeMenuItem="activateMenuItem" />
			<PurchaseHouseAnalysisReport v-if="menuItem == 'purchase-house-analysis-report'" :criteria="criteria" @activeMenuItem="activateMenuItem" />
			<PurchaseNakshatraInfluenceReport v-if="menuItem == 'purchase-nakshatra-influence-report'" :criteria="criteria" @activeMenuItem="activateMenuItem" />
			<PurchaseMahadashaAnalysisReport v-if="menuItem == 'purchase-mahadasha-analysis-report'" :criteria="criteria" @activeMenuItem="activateMenuItem" />
		</div>
		<div class="menu-area">
			<SideNavBar v-if="menuItem !== 'home'" :criteria="criteria" @activeMenuItem="activateMenuItem"/>
		</div>
	</div>
</template>

<script>
import { queryString } from '@/util.js'
import HTTP from '@/http-axios';
import ShowHeader from '@/components/natal-chart/ShowHeader'
import ShowNatalPlanets from '@/components/natal-chart/ShowNatalPlanets'
import ShowPlanetaryStrength from '@/components/natal-chart/ShowPlanetaryStrength'
import ShowAspects from '@/components/natal-chart/ShowAspects'
import ShowConjunction from '@/components/natal-chart/ShowConjunction'
import ShowDivisionalChart from '@/components/natal-chart/ShowDivisionalChart'
import ShowBhavaChart from '@/components/natal-chart/ShowBhavaChart'
import ShowPanchanga from '@/components/natal-chart/ShowPanchanga'
import ShowVimsottariDasas from '@/components/natal-chart/ShowVimsottariDasas'
import ShowFilteredVimsottariDasas from '@/components/natal-chart/ShowFilteredVimsottariDasas'
import ShowPDF from '@/components/natal-chart/ShowPDF'
import ShowPDFSummary from '@/components/natal-chart/ShowPDFSummary'
import SideNavBar from '@/components/natal-chart/SideNavBar'
import PurchaseCompleteNatalChart from '@/components/natal-chart/PurchaseCompleteNatalChart'
import ViewInterpretationReports from '../../views/ViewInterpretationReports.vue';
import PurchaseFoundationalReport from '../../views/PurchaseFoundationalReport.vue';
import PurchasePlanetaryPositionsReport from '../../views/PurchasePlanetaryPositionsReport.vue';
import PurchaseHouseAnalysisReport from '../../views/PurchaseHouseAnalysisReport.vue';
import PurchaseNakshatraInfluenceReport from '../../views/PurchaseNakshatraInfluenceReport.vue';
import PurchaseMahadashaAnalysisReport from '../../views/PurchaseMahadashaAnalysisReport.vue';

export default {
	components: { SideNavBar, ShowHeader, ShowPlanetaryStrength, ShowNatalPlanets, ShowAspects, ShowConjunction, 
				ShowDivisionalChart, ShowBhavaChart, ShowPanchanga, ShowVimsottariDasas, ShowFilteredVimsottariDasas, 
				ShowPDF, ShowPDFSummary, PurchaseCompleteNatalChart, ViewInterpretationReports,
				PurchaseFoundationalReport, PurchasePlanetaryPositionsReport, PurchaseHouseAnalysisReport, 
				PurchaseNakshatraInfluenceReport, PurchaseMahadashaAnalysisReport },
	props: {
		id: {
			type: String
		},
		criteria : {
			type: Object
		}
	},
	data() {
		return {
			menuItem: 'natal-planets',
			horoscope: {},
			pageLoading: false
		}
	},
	created() {
		this.getHoroscope()
	},
	methods: {
		activateMenuItem( activeMenuItem ) {
			this.menuItem = activeMenuItem;
			this.$emit('activeMenuItem', this.menuItem);
		},
		queryString: function( obj ) {
			return queryString(obj);
		},
		getHoroscope: function() {
			this.pageLoading = true;
			HTTP().post(`/api/data/horoscope?` + this.queryString(this.criteria))
			.then(response => {
				this.horoscope = response.data
				this.pageLoading = false
			})
			.catch(e => {
				console.log(e)
			})
		},

	}
}
</script>

<style scoped>

.chart-criteria {
	border: 0px solid;
	width:100%;
	height: 95%;
	float:left;
	margin-top:0px;
}

.screen-wrapper {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 

}

.content-area {
	border: 0px solid;
	width:88%;
	height: 95%;
	float:left;
	margin-top:0px;
}

.header-area {
	border: 0px solid;
	width:100%;
	height: 100px;
	float:left;
	margin-top:10px;
}

.menu-area {
	border: 0px solid;
	width: 10%;
	height: 95%;
	float:left;
	margin-top: 4px;
}

.menu-item {
	cursor: pointer;
	border: 1px solid;
	margin: 0px;
	background: cadetblue;
}

.screen-centered-img {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
}

@media screen and (min-width: 1205px) {
	.menu-area {
		border: 0px solid;
		width: 14%;
		height: 95%;
		float:left;
		margin-top: 4px;
	}
}

@media screen and (min-width: 1505px) {
	.menu-area {
		border: 0px solid;
		width: 6%;
		height: 95%;
		float:left;
		margin-top: 4px;
	}
}

</style>
