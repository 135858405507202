<template>
  <div class="container">
    <h2 class="report-title">Foundational Astrology Report</h2>
    <h3 class="report-title">Your Core Personality</h3>
    <p style="text-align: justify;">This report offers a <b>foundational analysis of your birth chart</b>, providing a basic yet insightful understanding of your core personality by exploring three key elements: Your <b>Ascendant</b>, <b>Moon Sign</b>, and <b>Nakshatra</b> (Lunar Mansion).</p>    

    <h3 class="report-title">What This Report Covers</h3>    
    <ul>
      <li style="padding: 5px;"><strong>Ascendant (Lagna): </strong> Your outward personality, approach to life, and physical traits.</li>
      <li style="padding: 5px;"><strong>Moon Sign (Rashi) :</strong> Your emotional nature, mental tendencies, and subconscious mind.</li>
      <li style="padding: 5px;"><strong>Lunar Mansion (Nakshatra) :</strong> The deeper karmic and psychological blueprint influencing your thoughts, behavior, and destiny.</li>
    </ul>

    <h3 class="report-title">How This Report Helps You</h3>
    <p style="text-align: justify;">
      This report gives a basic yet insightful understanding of your core personality. Helps you identify your natural strengths, challenges, and emotional tendencies.
    </p>

    <h3 class="report-title">Why You Should Get This Report</h3>
    <p style="text-align: justify;">
      A great starting point for beginners seeking an easy-to-understand astrology report. 
      It provides <b>foundational insights into the Natal Chart</b>, making it an excellent first step for deeper astrological exploration. 
      Best of all, it's free - everyone should take advantage of this opportunity!    
    </p>

    <br/>

    <h3 style="color: orangered;"><strong>Cost: FREE</strong></h3>

    <div align="center">
      <a @click="activate('chart-interpretations')" class="cta-button">Back</a>
      &nbsp;
      <a href="#" class="cta-button">Get Your Free Report</a>
    </div>

  </div>
</template>

<script>
import HTTP from '../http-axios';
import ApiBaseUrl from '../http-api-base-url'
import { queryString, generatePaidNatalChartKey } from '@/util.js'

export default {
  props: {
    criteria: {
      type: Object
    }
  },
  data() {
    return {
      orderDescription: 'Foundational Astrology Report from www.vedicastrochart.com',
    }
  },
  methods: {

		activate(menuItem) {
      console.log('PurchaseFoundational: ' + menuItem)
			this.$emit('activeMenuItem', menuItem);
		},

    downloadFoundationalChartPDF() {
      HTTP().get(ApiBaseUrl() + '/api/pdf/natalchart?' + this.queryString(this.criteria) + '&ct=' + this.getCriteriaToken(this.criteria), {
          responseType: 'blob'
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/pdf'
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = new String("VedicAstroChart" + (this.criteria.client ? " - " + this.criteria.client : ""))
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },

    queryString: function (obj) {
        return queryString(obj);
    },
    getCriteriaToken: function() {
        return generatePaidNatalChartKey(this.criteria);
    },
    showSampleNatalChart() {
        window.open(ApiBaseUrl() + '/api/pdf/sample/natalchart', "_blank");
    },  
  }

}

</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #333;
}
.container {
  max-width: 800px;
  margin: 120px auto;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 5px;
  border-radius: 12px;
  background: #EDF6F9; /* Updated background color */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border: 0px solid
}
.report-title {
  color: #087F8C;
  text-align: left;  
}
p, ul {
  text-align: left;
}
ul {
  padding-left: 20px;
}
.cta-button {
  display: inline-block;
  padding: 12px 25px;
  background-color: #087F8C;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  margin: 20px 0;
  font-weight: bold;
  text-align: center;
  width: fit-content;
  cursor: pointer;
}
.cta-button:hover {
  background-color: #218838;
}
@media (max-width: 768px) {
  .container {
    max-width: 100%;
    padding: 15px;
    margin: 15px;
    border-radius: 6px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);    
  }
  .cta-button {
    width: fit-content;
    padding: 12px 20px;
    margin: 20px 0;
  }
}
</style>
