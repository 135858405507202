<template>

	<div class="preHeader">
		<h3 style="color:#FBFBFF">
			Unlock the secrets of your cosmic blueprint with our personalized natal chart feature.
			On our website, you have the power to generate detailed charts that map out the positions of the planets at the moment of their birth.
			This insightful tool offers a window into the unique cosmic energies influencing your life,
			providing valuable insights into planetary strength, aspects and conjunctions, divisional charts, and Vimsottari Dasa periods.
		</h3>
	</div>

	<div class="screen-wrapper">

		<div class="natal-chart-intro-wrapper" v-if="firstVisit">
			<div class="natal-chart-intro">
				<u>
					<h3>Natal Chart</h3>
				</u>

				<p style="text-align: justify;">
                    Create Your <b>Free Vedic Astrology Natal or Birth Chart</b>. 
					Vedic astrology chart is a snapshot of planetary positions in the Zodiac at the time of birth where Earth is the place of the observer (geocentric). 
					Sidereal planetary positions are used to create a Vedic astrology chart and it includes:
				</p>
				<p style="text-align: left;">
                <ul style="text-align: left;">
                    <li>Planetary Positions</li>
                    <li>Planetary Strength</li>
                    <li>Planetary Aspects</li>
                    <li>Planetary Conjunctions</li>
                    <li>House Cusps and Midpoints</li>
                    <li>Divisional Chart Images</li>
                    <li>Vimsottari Dasa Periods</li>
                    <li>Natal Panchanga (Vedic Calendar)</li>
					<li>Detailed <b>Interpretations</b></li>
                    <li>and more as a <b>PDF</b> document</li>
                </ul>
                </p>				
			</div>		

			<div class="link-button-area">
				<a @click="viewNatalChart" class="link-button">
					Create Natal Chart
				</a>
			</div>
		</div>

		<!-- DESKTOP -->
		<div class="desktop-chart-criteria" v-if="menuItem == 'home' && deviceType == 'BS' && !firstVisit">
			<ChartCriteria @criteria="setCriteria" />
		</div>

		<div class="desktop-natal-chart-area" v-if="menuItem !== 'home' && deviceType == 'BS' && !firstVisit">
			<NatalChart :id="'nnc'" :criteria="criteria" @activeMenuItem="activateMenuItem" />
		</div>

		<!-- MOBILE -->
		<div class="mobile-chart-criteria" v-if="menuItem == 'home' && deviceType == 'SS' && !firstVisit">
			<MobileChartCriteria @criteria="setCriteria" />
		</div>

		<div class="mobile-natal-chart-area" v-if="menuItem !== 'home' && deviceType == 'SS' && !firstVisit">
			<MobileNatalChart :criteria="criteria" @activeMenuItem="activateMenuItem" />
		</div>

	</div>


</template>

<script>
import ChartCriteria from '@/components/ChartCriteria'
import MobileChartCriteria from '@/components/MobileChartCriteria'
import NatalChart from '@/components/natal-chart/NatalChart'
import MobileNatalChart from '@/components/small-screen-natal-chart/MobileNatalChart'


export default {
	components: { ChartCriteria, MobileChartCriteria, NatalChart, MobileNatalChart },
	data() {
		return {
			menuItem: 'home',
			criteria: {},
			deviceType: (window.innerWidth > 1205 ? 'BS' : 'SS'),
			firstVisit: localStorage.getItem('ncloc') ? false : true
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},	
	beforeUnmount() { 
		window.removeEventListener('resize', this.onResize); 
	},
	methods: {
		setCriteria( criteria ) {
			this.criteria = criteria
			this.menuItem = 'charts'
		},		
		activateMenuItem( activeMenuItem ) {
			this.menuItem = activeMenuItem;
		},
		onResize() {
			this.deviceType = (window.innerWidth > 1205 ? 'BS' : 'SS')
		},
		viewNatalChart() {
			this.firstVisit = false
		}
	}
}
</script>

<style scoped>

.preHeader {
	display:none !important;
	visibility: hidden !important;
	color: #FBFBFF !important;
	text-align: center;
}      

.screen-wrapper {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.desktop-chart-criteria {
	border: 0px solid;
	width:100%;
	height: 95%;
	float:left;
	margin-top:0px;
}

.mobile-chart-criteria {
	border: 0px solid;
	width:100%;
	height: 95%;
	float:left;
	margin-top:0px;
}

.desktop-natal-chart-area {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.mobile-natal-chart-area {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.natal-chart-intro-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;  
    border-radius: 10px;
    padding-top: 10px;
  }

.natal-chart-intro {
  border: 1px solid;
  border-color: #a0a0a0;
  align-content: center;
  background: #EDF6F9;
  border-radius: 10px;
  margin-top: 50px;
  max-width: 800px;
  width: 65%;
  padding: 15px;
  
}

.link-button {
  border-radius: 5px;
  background-color: #087F8C;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  padding: 12px;
  text-decoration: none;
  cursor: pointer;
}

.link-button-area {
  width: 95%;
  border: 0px solid;
  padding: 30px;
}


</style>
