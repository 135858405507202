<template>
  <div class="container">
    <h2>Your Personalized Astrology Reports</h2>
    <p style="text-align: justify;">
      Your <b>personalized astrology reports</b> are meticulously crafted using authentic Vedic astrology principles to provide deep, personalized insights into your life. 
      Designed to <b>bring you awareness, clarity, and guidance on major life themes</b>, each report empowers you to navigate your journey with confidence and make informed decisions proactively.      
    </p>

    <!--
    <div 
      class="report-box"
      @mouseover="hoverIndex = 1"
      @mouseleave="hoverIndex = null"
      :class="{ 'hovered': hoverIndex === 1 }"
    >
      <h3 class="report-title">Foundational Astrology Report</h3>
      <p>This report offers a <b>foundational analysis of your birth chart</b>, providing a basic yet insightful understanding of your core personality by exploring three key elements: Your <b>Ascendant</b>, <b>Moon Sign</b>, and <b>Nakshatra</b> (Lunar Mansion).</p>

      <a @click="activate('purchase-foundational-report')" class="cta-button">Learn More</a>      
    </div>
    -->

    <div 
      class="report-box"
      @mouseover="hoverIndex = 2"
      @mouseleave="hoverIndex = null"
      :class="{ 'hovered': hoverIndex === 2 }"
    >
    <h3 class="report-title">Planetary Positions Report</h3>
      <p>The Planetary Positions Report analyzes the <b>placement of planets in your birth chart</b>, explaining how they influence various aspects of your life. It covers <b>planetary dignity, rulership, strengths, challenges, and their combined effects on major life areas</b>, including career, relationships, health, wealth and personal growth.</p>

      <a @click="activate('purchase-planetary-positions-report')" class="cta-button">Learn More</a>      
    </div>

    <div 
      class="report-box"
      @mouseover="hoverIndex = 3"
      @mouseleave="hoverIndex = null"
      :class="{ 'hovered': hoverIndex === 3 }"
    >
    <h3 class="report-title">House Analysis Report</h3>
      <p>The House Analysis Report <b>explores the 12 houses in your birth chart</b>, revealing  how planetary placements within these houses influence their expression, ultimately impacting key aspects of your life.</p>

      <a @click="activate('purchase-house-analysis-report')" class="cta-button">Learn More</a>            
    </div>

    <div 
      class="report-box"
      @mouseover="hoverIndex = 4"
      @mouseleave="hoverIndex = null"
      :class="{ 'hovered': hoverIndex === 4 }"
    >
    <h3 class="report-title">Nakshatra Influence Report</h3>
      <p>An in-depth <b>exploration of the Nakshatras in which your planets reside</b>, uncovering their influence on the expression of planetary energies in your birth chart.
        Each Nakshatra carries unique characteristics that <b>refine the way a planet manifests its energy in your life</b>.
      </p>

      <a @click="activate('purchase-nakshatra-influence-report')" class="cta-button">Learn More</a>            
    </div>

    <div 
      class="report-box"
      @mouseover="hoverIndex = 5"
      @mouseleave="hoverIndex = null"
      :class="{ 'hovered': hoverIndex === 5 }"
    >
    <h3 class="report-title">Mahadasha Analysis Report</h3>
      <p>The Mahadasha Analysis Report <b>explains the planetary periods</b> (Mahadashas) you experience in life, unveiling the karmic cycles and its profound influence on your life’s journey and key turning points.
        Each Mahadasha is ruled by a different planet and <b>shapes your key life areas over a specific time frame</b>.
      </p>

      <a @click="activate('purchase-mahadasha-analysis-report')" class="cta-button">Learn More</a>            
    </div>

    <h2>Find the Right Report for You</h2>
    <p style="text-align: justify;">
      Each report offers unique insights <b>tailored based on the different components of your
      natal chart</b>. If you're still unsure, consider these recommendations:  
    </p>
    <ul>
      <li style="padding: 5px;"><b>Want to understand planetary influences in detail?</b> → Get the Planetary Positions Report</li>  
      <li style="padding: 5px;"><b>Curious about how life areas are shaped</b> by Astrological houses? → Get the House Analysis Report</li>  
      <li style="padding: 5px;"><b>Interested in how Nakshatras impact planetary expression?</b> → Get the Nakshatra Influence Report</li>  
      <li style="padding: 5px;"><b>Looking to uncover insights from your past and strategically navigate your future using Dasha cycles?</b> 
        → Get the Mahadasha Analysis Report</li>  
    </ul>
    <p style="text-align: justify;">
      Whichever report you choose, <b>you'll gain valuable insights and self-awareness</b> to make informed decisions and align with your true path.
    </p>

    <br/>
    <h3>For Those Who Want Complete Insights – Get All Reports!</h3>
    <p style="text-align: justify;">
      For those seeking a complete, in-depth understanding of their birth chart, <b>getting all reports provides the fullest picture of how planetary energies shape your life</b>.
      Whether you are new to Vedic astrology or looking for advanced insights, <b>getting all
      reports ensures nothing is left unexplored</b> - providing you with the most
      comprehensive and personalized Vedic astrological guidance available.      
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoverIndex: null
    };
  },
  methods: {

    activate(menuItem) {
      this.$emit('activeMenuItem', menuItem);
    },

  }
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
  background-color: #f8f9fa;
  color: #333;
}

.report-title {
  color: #087F8C
}

p, ul {
  text-align: left;
}
ul {
  padding-left: 20px;
}

.container {
  max-width: 800px;
  margin: 120px auto;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.report-box {
  background: #EDF6F9;
  padding: 15px;
  margin: 15px 0;
  border-left: 5px solid #2c3e50;
  border-radius: 5px;
  transition: transform 0.2s ease, background-color 0.2s ease;
  text-align: left; /* Left-aligned content */
}
.report-box.hovered {
  transform: scale(1.01);
  background: #bbdefb;
}
h1, h2, h3 {
  color: #2c3e50;
  text-align: left; /* Left-align headings */
}
p {
  line-height: 1.6;
  text-align: left; /* Left-align paragraphs */
}
.cta-button {
  display: inline-block;
  padding: 12px 25px;
  background-color: #087F8C;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  margin: 20px 0;
  font-weight: bold;
  text-align: center;
  width: fit-content;
  cursor: pointer;
}
.cta-button:hover {
  background-color: #218838;
}

/* Ensuring margin consistency across all screen sizes */
@media (max-width: 800px) {
  .container {
    margin: 20px; /* Apply same margin on mobile devices */
    padding: 15px; /* Reduce padding slightly for better spacing */
  }
}
</style>
