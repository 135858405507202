<template>
    <div class="preHeader">
      <h2>Free Natal Chart Widget</h2>
      <p>Are you interested in including this  free natal chart widget on your website?</p>
      <p>Below is sample code for how to include/embed above natal chart widget on your website.
          Contact us for your widget url.
      </p>
    </div>

    <div class="home-wrapper">
        <h2>Free Natal Chart Widget</h2>
        <div class="widget-intro">
          Are you interested in including this <br/>free natal chart widget on your website?
        </div>

        <div class="widget-area">
            <iframe
              width="350"
              height="325"
              style="border:0"
              scrolling="no"
              :src="widgetUrl">
            </iframe>
        </div>

        <div class="widget-intro">
          Below is sample code for how to include/embed above natal chart widget on your website.
          <br />
          Contact us for your widget url.
          <br /><br />
        </div>

        <div class="widget-embed-code" align="center">
          <pre>
  &lt;iframe 
    width="350"
    height="325"
    style="border:0"
    scrolling="no"
    src="widget-url-for-your-website">
  &lt;/iframe&gt;
          </pre>
        </div>

        <div class="widget-contact-button-area">
            <a class="widget-contact-link" @click="$router.push('contact')">&nbsp;Contact Us&nbsp;</a>
        </div>
    </div>
</template>


<script>

import DomainUrl from "../http-domain-url";

export default {
    components: {},
    data() {
        return {
            widgetUrl : DomainUrl() +  "/natal-chart-widget",
        };
    },
    created() {
    },
    mounted() {},

    updated() {},

    methods: {
        DomainUrl: function () {
            return DomainUrl();
        },
    },
    beforeUnmount() {
    },
};
</script>


<style scoped>

body {
  font-family: Arial, Helvetica, sans-serif;
}

.preHeader {
	display:none !important;
	visibility: hidden !important;
	color: #FBFBFF !important;
	text-align: center;
}      

.widget-contact-link {
  border-radius: 5px;
  background-color: #087F8C;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  padding: 12px;
  text-decoration: none;
}

.home-wrapper {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;  
}

.widget-contact-button-area {
  width: 100%;
  border: 0px solid;
  padding: 30px;
  cursor: pointer;
}

.widget-area {
  border: 0px solid;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 350px;
  height: 330px;
  cursor: pointer;
  text-align: center;
}

.widget-intro {
  width: 100%;
  text-align: center;
  align-content: center;
  margin: 15px;
}

.widget-embed-code {
  text-align: left;
  border: 1px solid;
  background-color: #eeeeee;
  width: 30%;
}


@media screen and (max-width: 1605px) {

  .home-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;  
    border-radius: 10px;
    padding-top: 10px;
  }
 
  .widget-contact-button-area {
    width: 90%;
    border: 0px solid;
    padding: 30px;
    cursor: pointer;
  }

  .widget-area {
    border: 0px solid;
    width: 90%;
    padding: 5px;
    cursor: pointer;
  }

  .widget-intro {
    width: 100%;
    padding: 5px;
  }

  .widget-embed-code {
    text-align: left;
    border: 1px solid;
    background-color: #eeeeee;
    width: 50%;
  }

}

@media screen and (max-width: 1205px) {

  .home-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;  
    border-radius: 10px;
    padding-top: 10px;
  }
 
  .widget-contact-button-area {
    width: 100%;
    border: 0px solid;
    padding: 30px;
    cursor: pointer;
  }

  .widget-area {
    border: 0px solid;
    width: 100%;
    padding: 5px;
    cursor: pointer;
  }

  .widget-intro {
    width: 100%;
    padding: 5px;
  }

  .widget-embed-code {
    text-align: left;
    border: 1px solid;
    background-color: #eeeeee;
    width: 60%;
  }

}

@media screen and (max-width: 705px) {

  .home-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;  
    border-radius: 10px;
    padding-top: 10px;
  }
 
  .widget-contact-button-area {
    width: 100%;
    border: 0px solid;
    padding: 30px;
    cursor: pointer;
  }

  .widget-area {
    border: 0px solid;
    width: 100%;
    padding: 5px;
    cursor: pointer;
  }

  .widget-intro {
    width: 100%;
    padding: 5px;
  }

  .widget-embed-code {
    text-align: left;
    border: 1px solid;
    background-color: #eeeeee;
    width: 95%;
  }

}
</style>