<template>
  <div class="container">
    <!--
    <h1>Welcome to VedicAstroChart</h1>
    <p class="description">
      Explore the profound insights of Vedic Astrology with our modern tools.
      Our astrological calculations are handled with the utmost care and powered by high-precision ephemeris, ensuring unparalleled accuracy in planetary positions.
      Every aspect of our reports is meticulously crafted, combining deep astrological wisdom with precise computational methods to provide you with reliable and detailed insights.    
    </p>
    -->

    
    <div class="grid">
      <router-link to="/natal-chart" class="card">
        <h2>Natal Chart</h2>
        <p style="text-align: justify;">Create your personalized Vedic astrology birth chart with precision using our advanced chart calculator.</p>
      </router-link>
      <router-link to="/planetary-positions" class="card">
        <h2>Planetary Positions</h2>
        <p style="text-align: justify;">Accurate planetary positions and Vedic Panchanga — seamlessly explore past, present, and future planetary alignments.</p>
      </router-link>
      <router-link to="/transits" class="card">
        <h2>Transits</h2>
        <p style="text-align: justify;">Track planetary transits with precision across Zodiac signs, Nakshatras (lunar mansions), exact degrees on the ecliptic and direct / retrograde periods.</p>
      </router-link>
      <router-link to="/panchanga" class="card">
        <h2>Panchanga</h2>
        <p style="text-align: justify;">Step into the harmonious flow of cosmic time with our Panchanga — the Vedic calendar that illuminates each moment's essence through its five sacred components.</p>
      </router-link>
      <router-link to="/jamakkol" class="card">
        <h2>Jamakkol Horary</h2>
        <p style="text-align: justify;">Jamakkol Prasanam is a Horary method in Vedic astrology, offering clarity and guidance for a wide range of life's questions.</p>
      </router-link>
      <router-link to="/widgets" class="card">
        <h2>Widgets</h2>
        <p style="text-align: justify;">Looking for a free natal chart widget for your website or blog? Embed our sleek, single-page natal chart widget effortlessly!</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomePage",
};
</script>

<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
}

.container {
  border: 0px solid;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f3f4f6;
  text-align: center;
}

h1 {
  font-size: 2.5rem;
  color: #087F8C;
  margin-bottom: 20px;
}

.description {
  font-size: 1.2rem;
  color: #4a4a4a;
  max-width: 600px;
  margin-bottom: 30px;
  text-align: justify;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  max-width: 800px;
  width: 100%;
  padding: 20px;
}

@media (min-width: 768px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    max-width: 800px;
    width: 100%;
    max-width: 650px;
    padding: 20px;
    border: 0px solid;
  }
}

.card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s;
  text-decoration: none;
  color: inherit;
  display: block;
}

.card:hover {
  background: #e0e7ff;
}

h2 {
  font-size: 1.5rem;
  color: #087F8C;
  margin-bottom: 10px;
}

.card p {
  font-size: 1rem;
  color: #6b7280;
}
</style>
