<template>
  <div class="container">
    <h2 class="report-title">Planetary Positions Report</h2>
    <h3 class="report-title">Decode Your Planetary Placements</h3>
    <p style="text-align: justify;">
      Have you ever wondered why you think, feel, and react the way you do? Why certain life
      patterns keep repeating, or why some areas of life seem easier than others? The
      Planetary Positions Report is designed to <b>give you deep, meaningful insights</b> into how
      the placement of planets in your birth chart <b>shapes your personality, life experiences,
      and potential</b>.
    </p>    

    <h3 class="report-title">What This Report Covers</h3>    
    <p>
      The Planetary Positions Report analyzes the placement of planets in your birth chart,
      explaining how they influence various aspects of your life. It covers <b>planetary dignity,
      rulership, strengths, challenges, and their combined effects on major life areas</b>,
      including career, relationships, health, wealth and personal growth.
    </p>

    <h3 class="report-title">How This Report Helps You</h3>
    <ul>
      <li style="padding: 5px;"><b>Understand the impact of each planet in your chart</b> and how they shape your
        destiny.</li>
      <li style="padding: 5px;"><b>Gain clarity on strengths and challenges</b> associated with each planetary
        placement.</li>
      <li style="padding: 5px;"><b>Receive practical guidance</b> on aligning with planetary energies for success.</li>
    </ul>

    <h3 class="report-title">How This Report Is Unique</h3>
    <p style="text-align: justify;">
      Unlike traditional astrology readings that give vague or disjointed insights, this report
      offers a thorough and <b>organized way to comprehend your planetary placements</b>
    </p>

    <h3 class="report-title">Why You Should Get This Report</h3>
    <p style="text-align: justify;">
      If you want a <b>detailed breakdown of how planetary positions affect your life</b> and
      what they reveal about your personality, strengths, challenges and potential, this report is a must-have.      
    </p>

    <h3 class="bonus-title">Exclusive Bonus: Your Essential Natal Chart</h3>
    <p style="text-align: justify;">
      Each of our reports includes <b>Your Essential Natal Chart</b>, meticulously crafted using high-precision ephemeris data to ensure absolute accuracy in planetary positions. 
      Every calculation in your essential natal chart is performed with utmost precision, adhering to the core principles of Vedic astrology. 
      <b>Spanning 23 pages</b>, your Essential Natal Chart provides a comprehensive foundation for deeper astrological insights. 
      It includes <b>planetary positions, strengths, aspects, conjunctions, dignity, astrological house cusps and midpoints, 20 divisional charts, Vimsottari Dasha periods, and natal Panchanga</b>. 
      Designed as a valuable <b>companion to your personalized astrology reports</b>, it enhances your understanding of planetary influences and life themes with unparalleled accuracy.
    </p>

    <h3 class="bonus-title">Exclusive Bonus: Introduction to Vedic Astrology Guide</h3>
    <p style="text-align: justify;">
      Each of our reports includes an <b>Introduction to Vedic Astrology Guide</b>, carefully designed to help you build a strong foundation in this ancient system. 
      This guide covers essential concepts such as the <b>9 planets, 12 zodiac signs, 12 houses, 27 nakshatras, planetary dignity, dasha cycles, and transits</b>. 
      More importantly, with multiple example interpretations, it <b>explains how planets, signs, and houses interact</b> to shape planetary influences.
      By understanding these key principles, <b>you'll develop the confidence and clarity to interpret your reports</b> with greater accuracy. 
      This foundational knowledge empowers you to navigate your astrological exploration with depth, meaning, and a newfound sense of clarity.
    </p>

    <h3 class="bonus-title">Exclusive Bonus: Your Foundational Astrology Report</h3>
    <p style="text-align: justify;">
      Each of our reports includes <b>Your Foundational Astrology Report</b>, providing a basic yet insightful understanding of 
      your core personality by exploring three key elements: 
    </p>
    <ul>
      <li style="padding: 5px;"><strong>Ascendant (Lagna): </strong> Your outward personality, approach to life, and physical traits.</li>
      <li style="padding: 5px;"><strong>Moon Sign (Rashi) :</strong> Your emotional nature, mental tendencies, and subconscious mind.</li>
      <li style="padding: 5px;"><strong>Lunar Mansion (Nakshatra) :</strong> The deeper karmic and psychological blueprint influencing your thoughts, behavior, and destiny.</li>
    </ul>

    <br/>

    <h3 style="color: orangered;"><strong>Cost: ${{orderPrice}}</strong></h3>

    <div align="center">
      <a @click="activate('chart-interpretations')" class="cta-button">Back</a>
      &nbsp;
      <a @click="showSampleReport()" class="cta-button">View Sample Report</a>
    </div>

    <div class="payment-methods-container">
      <div class="apple-pay" @click="stripePurchase('apple-pay')"><img src="../assets/apay.png" alt="Apple Pay" width="80" /></div>
      <div class="google-pay" @click="stripePurchase('google-pay')"><img src="../assets/gpay.png" alt="Apple Pay" width="80" /></div>
      <div class="credit-cards" @click="stripePurchase('credit-cards')"><img src="../assets/stripe-credit-cards.png" alt="Apple Pay" width="200" /></div>
    	<div class="paypal-container" ref="paypalContainerPPR"></div>
	  </div>

  </div>
</template>

<script>
import HTTP from '../http-axios';
import ApiBaseUrl from '../http-api-base-url'
import { queryString, generatePaidNatalChartKey } from '@/util.js'

export default {
  props: {
    criteria: {
      type: Object
    }
  },
  data() {
    return {
      orderDescription: 'Planetary Positions Report from www.vedicastrochart.com',
      orderPrice: '14.95',
      paypalOrder: {
        orderId: '',
        payerGivenName: '',
        payerSurname: '',
        payerEmail: '',
        orderData: '',
        pdfUrl: '',
      }
    }
  },
  mounted() {

      let serverUrl = ApiBaseUrl();

      const script = document.createElement("script");

      if ( serverUrl.includes('vedicastrochart.com') ) {

        // PRODUCTION - LIVE
        script.src = "https://www.paypal.com/sdk/js?client-id=AdmcXR3kme4bPK1lX05Gx5J03DCwudEhCnKPDU23FRNDbiz8ReoYPDIaGH68T828IthtIqzwFDjxcPkr&currency=USD&disable-funding=credit,card";

      } else {

        // SANDBOX - TESTING
        script.src = "https://www.paypal.com/sdk/js?client-id=Af_ir11mM_o7A22aqOu2azj9FSXrfO2h_xjOeLP0hLgv0LR93ddjRAqxtQoVcYUJ_PMgP2lubzB3isGI&currency=USD&disable-funding=credit,card";

      }

      script.addEventListener("load", this.setLoaded)
      document.body.appendChild(script)
      },

  methods: {

		activate(menuItem) {
			this.$emit('activeMenuItem', menuItem);
		},

    setLoaded() {
      paypal.Buttons({
        // Sets up the transaction when a payment button is clicked
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              description: this.orderDescription,
              amount: {
                value: this.orderPrice,
                currency_code: "USD"
              }
            }],
            application_context: {
              shipping_preference: "NO_SHIPPING",
            }
          });
        },
        // Finalize the transaction after payer approval
        onApprove: (data, actions) => {

          const orderHandler = (orderData) => {
            var orderJsonString = JSON.stringify(orderData, null, 2);
            console.log('Capture result', orderData, orderJsonString);
            this.purchaseSuccess(orderData.id, orderData.payer.name.given_name, orderData.payer.name.surname, orderData.payer.email_address, orderJsonString);
          };

          return actions.order.capture().then(orderHandler);
        },

        onError: (err) => {
          console.log(JSON.stringify(err, null, 2));
          this.purchaseFailure(JSON.stringify(err, null, 2))
        }

      }).render(this.$refs.paypalContainerPPR);

    },

    purchaseSuccess: function (orderId, payerGivenName, payerSurname, payerEmail, orderJsonString) {

      // Set paypal order details
      this.paypalOrder.orderId = orderId;
      this.paypalOrder.payerGivenName = payerGivenName;
      this.paypalOrder.payerSurname = payerSurname;
      this.paypalOrder.payerEmail = payerEmail;
      this.paypalOrder.orderData = orderJsonString;

      // Track paypal order success
      HTTP().post(`/api/purchase/report/planetaryposition/paypal/order/sucess?` + this.queryString(this.criteria) + '&' + this.queryString(this.paypalOrder))
        .then(response => {
          console.log('order success: ');
          document.documentElement.innerHTML = response.data
        })
        .catch(e => {
          console.log(e)
        })
    },

    purchaseFailure: function (errorMessage) {

      // Track paypal order failure
      HTTP().post(`/api/purchase/report/planetaryposition/paypal/order/failure?` + this.queryString(this.criteria) + '&error=' + errorMessage)
        .then(response => {
          console.log('order failure:' + errorMessage);
          alert("Error while completing your PayPal Order : " + errorMessage);
        })
        .catch(e => {
          console.log(e)
        })
    },

    queryString: function (obj) {
        return queryString(obj);
    },
    getCriteriaToken: function() {
        return generatePaidNatalChartKey(this.criteria);
    },

    stripePurchase: function(paymentMethod) {
      HTTP().post(`/api/purchase/report/planetaryposition/stripe/order/checkout?` + this.queryString(this.criteria) + '&pm=' + paymentMethod)
        .then(response => {
		    window.location.href = response.data	
        })
        .catch(e => {
          console.log(e)
        })
	  },

    showSampleReport() {
        window.open(ApiBaseUrl() + '/api/report/sample/pppr?' + this.queryString(this.criteria), "_blank");
    },  
  }

}

</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #333;
}
.container {
  max-width: 800px;
  margin: 120px auto;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 5px;
  border-radius: 12px;
  background: #EDF6F9; /* Updated background color */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border: 0px solid
}
.report-title {
  color: #087F8C;
  text-align: left;  
}
.bonus-title {
  color: orangered;
  text-align: left;  
}
p, ul {
  text-align: left;
}
ul {
  padding-left: 20px;
}
.cta-button {
  display: inline-block;
  padding: 12px 25px;
  background-color: #087F8C;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  margin: 20px 0;
  font-weight: bold;
  text-align: center;
  width: fit-content;
  cursor: pointer;
}
.cta-button:hover {
  background-color: #218838;
}

.payment-methods-container {
  margin: auto;
	border: 0px solid;
	width: 90%;
	padding: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	padding: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
    padding: 15px;
    margin: 15px;
    border-radius: 6px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);    
  }
  .cta-button {
    width: fit-content;
    padding: 12px 20px;
    margin: 20px 0;
  }
  .payment-methods-container {
    margin: 5px auto;
    border: 0px solid;
    width: 90%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;	
    cursor: pointer;
  }
  .apple-pay {
    margin: 10px;
  }
  .google-pay {
    margin: 10px;
  }
  .credit-cards {
    margin: 10px;
    border: 0px solid;
  }
  .paypal-container {
    margin: 10px;
    border: 0px solid;
  }

}
</style>
